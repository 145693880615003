import client from "@/api";
import store from "@/store";
import { getFirstError } from "@/utils/errorResponse";
import { limpaCPF } from "@/utils/masks";
import {
  _rotaAtualizarCupom,
  _rotaListarCupomStatus,
  // _rotaListarCupomPorStatus,
  _rotaListarTodosCupons,
  _rotaListarProdutosPorCupom,
  _rotaGerarRelatorioCupons,
  _rotaListarCupomObterDetalhes,
} from "@/api/_caminhosApi";

export function cupomListarStatus() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_STATUS_ITENS", "");
  client
    .get(`${_rotaListarCupomStatus()}`)
    .then((resp) => {
      let filtros = [{ descricao: "Todos", id: null }, ...resp.data];
      store.commit("cupom/SET_STATUS_ITENS", filtros);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function downloadWithAxios(url, title) {
  return new Promise((resolve) => {
    client
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((resp) => {
        console.log(resp);
        fetch(resp.data.url)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = title;
            document.body.appendChild(a);
            if (a.download !== "null") {
              a.click();
            }
            window.URL.revokeObjectURL(url);
          });
        resolve(resp);
      })
      .catch((erro) => {
        alert("Arquivo não pôde ser baixado.");
        console.log(erro);
      });
  });
}

export function obterDetalhes(id) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_TABELA_DETALHE", "");
  client
    .get(`${_rotaListarCupomObterDetalhes()}?idCupom=${parseInt(id)}`)
    .then((resp) => {
      store.commit("cupom/SET_TABELA_DETALHE", resp);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function cupomListarTodos(page, qtd, filtro) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_TABELA", "");

  let rota = `?pagina=${page}&quantidadeItemPorPagina=${qtd}`;
  var data = {
    nomeUsuario: filtro && filtro.nome ? filtro.nome : null,
    emailUsuario: filtro && filtro.email ? filtro.email : null,
    cpfUsuario: filtro && filtro.cpf ? limpaCPF(filtro.cpf) : null,
    numeroCupom: filtro && filtro.numeroCupom ? filtro.numeroCupom : null,
    idStatus: filtro && filtro.status.id ? filtro.status.id : null,
    dataInicial: filtro && filtro.dataInicial ? filtro.dataInicial : null,
    dataFinal: filtro && filtro.dataFinal ? filtro.dataFinal : null,
    premiacaoInstantanea:
      filtro && filtro.premiacaoInstantanea
        ? filtro.premiacaoInstantanea
        : null,
  };

  client
    .post(`${_rotaListarTodosCupons()}${rota}`, data)
    .then((resp) => {
      store.commit("cupom/SET_TABELA", resp.data.lista);
      store.commit("cupom/SET_DATA_PAGINACAO", resp.data.paginacao);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
//
export function cupomAtualizar(bodyData, motivoReprovacao) {
  if (!motivoReprovacao && bodyData.cupomStatus.id != 1) {
    store.commit("interacoes/SET_MODAL", {
      ativado: true,
      mensagem: "Descreva o motivo da reprovação.",
      tipo: "erro",
    });
    store.commit("interacoes/SET_LOADING", false);
    this.$store.commit("interacoes/SET_MODAL_BLOQUEIO", {
      ativado: false,
      mensagem: "",
      tipo: "",
    });
    return;
  }
  bodyData.motivoReprovacao = motivoReprovacao;
  bodyData.idUsuarioPainel = window.localStorage.getItem("idUser");
  store.commit("interacoes/SET_LOADING", true);
  client
    .put(`${_rotaAtualizarCupom()}`, bodyData)
    .then((resp) => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.mensagem,
        tipo: "sucesso",
      });
      store.commit("cupom/SET_CUPOM_TELA", "Lista");
    })
    .catch((erro) => {
      console.log(erro);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data
          ? getFirstError(erro.response.data)
          : "Erro inesperado. Informe a área técnica.",
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
      this.$store.commit("interacoes/SET_MODAL_BLOQUEIO", {
        ativado: false,
        mensagem: "",
        tipo: "",
      });
    });
}
//
export function cupomListarProdutos(cupomId) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_PRODUTOS_MODERAR", "");
  client
    .get(`${_rotaListarProdutosPorCupom()}?cupomId=${cupomId}`)
    .then((resp) => {
      store.commit("cupom/SET_PRODUTOS_MODERAR", resp.data);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function gerarRelatorio() {
  return new Promise((resolve) => {
    store.commit("interacoes/SET_LOADING_RELATORIO", true);
    client
      .get(_rotaGerarRelatorioCupons())
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        console.log(erro);
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING_RELATORIO", false);
      });
  });
}
