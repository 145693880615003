<template>
  <div class="modal-bloqueia" :class="[modalAtivado]">
    <div class="modal-bloqueia-principal">
      <div class="centro">
        <span @click="fecharModal"
          ><img src="~@/assets/images/icones/close.png" alt="Fechar"
        /></span>
        <template>
          <p>{{ modal.mensagemModal }}</p>
          <textarea v-model.trim="texto" value="" rows="8" cols="60"></textarea>

          <div class="modal-bloqueia-botoes">
            <button
              class="bloqueia"
              @click="moderarUsuario(idUserBloqueado, texto, true)"
              v-if="modal.tipoModal == 'bloquearUsuario'"
            >
              Bloquear
            </button>
            <button
              class="bloqueia"
              @click="cupomAtualizar(dataCupom, texto)"
              v-if="modal.tipoModal == 'reprovarCupom'"
            >
              Reprovar
            </button>
            <button class="voltar" @click="fecharModal">Voltar</button>
          </div>
        </template>
      </div>
      <div class="opacity"></div>
    </div>
  </div>
</template>

<script>
import { moderarUsuario } from "@/api/usuario";
import { cupomAtualizar } from "@/api/cupom";

export default {
  data() {
    return {
      texto: ""
    };
  },
  computed: {
    modal() {
      return this.$store.state.interacoes.modalBloqueia;
    },
    modalAtivado() {
      return this.modal.modalAtivado ? "active" : "";
    },
    idUserBloqueado() {
      return this.$store.state.user.idUsuarioBloqueado;
    },
    dataCupom() {
      return this.$store.state.cupom.dataCupom;
    }
  },
  methods: {
    moderarUsuario,
    cupomAtualizar,
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_BLOQUEIO", {
        ativado: false,
        mensagem: "",
        tipo: ""
      });
    }
  },
  enviarBloqueio(idUserBloqueado, texto) {
    this.bloquearUsuario(idUserBloqueado, texto);
    this.texto = "";
  }
};
</script>

<style lang="scss" scoped>
.opacity {
  width: 100%;
  position: absolute;
  background: #000;
  height: 112vh;
  opacity: 0.8;
  top: 0;
  left: 0;
}
.modal-bloqueia {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  transition: 0.3s;
  transform: rotateX(90deg);
  opacity: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  &.active {
    visibility: visible;
    transform: rotateX(0deg);
    opacity: 1;
    height: 100vh;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2em;
  }
}
.modal-bloqueia-principal {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .centro {
    position: relative;
    background: white;
    position: relative;
    z-index: 1;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    span {
      position: absolute;
      width: 32px;
      height: 32px;
      top: -35px;
      right: 3px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    textarea {
      margin: 15px 0px;
      border: 1px solid $neutro4;
      resize: none;
    }
    .modal-bloqueia-botoes {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      button {
        width: 120px;
        border: none;
        color: #fff;
        height: 30px;
        border-radius: 15px;
        font-size: 18px;
        margin-left: 15px;
      }
      .bloqueia {
        background: red;
      }
      .voltar {
        background: green;
      }
    }
  }
}
</style>
